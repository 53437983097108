/* img {
  border-radius: 10px;
  border-width: 2px;
  border-color: black;
} */

body {
  /* background: #101c2c; */
  overflow-x: hidden;
}

.top-navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
}


.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}


.swiper-pagination-bullet {
  background-color: #4a4a4a;
  /* Customize the bullet color */
}

.swiper-pagination-bullet-active {
  background-color: #0e1b29;
  /* Customize the active bullet color */
}

.swiper-button-next,
.swiper-button-prev {
  /* Customize the navigation button color */
  color: #4a4a4a;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #0e1b29;
  /* Customize the hover color */
}